<template>
    <div class="winex-pager-wrap" v-if="totalPage > 1">
        <ul class="pager-list">
            <li class="pager-first" v-if="currentPage !== 1" @click="movePage(1)"></li>
            <li class="pager-prev" v-if="currentPage !== 1" @click="movePage(currentPage -1)"></li>

            <li class="pager-item" v-for="(item, index) in loopPager" :key="index" :class="{on:(item + startPage - 1) === currentPage}" @click="movePage(item + startPage - 1)">{{item + startPage - 1}}</li>

            <li class="pager-next" v-if="currentPage !== totalPage" @click="movePage(currentPage + 1)"></li>
            <li class="pager-last" v-if="currentPage !== totalPage" @click="movePage(totalPage)"></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "WinexPagination",
        props: {
            movePage: {
                default: () => {},
                type:Function
            },
            totalPage: {
                default:10
            },
            currentPage: {
                default:1,
                required:true
            }
        },
        watch: {
            currentPage: function () {
                this.setPager()
            },
            totalPage: function () {
                this.selfTotalPage = this.totalPage
                this.setPager()
            }
        },
        data() {
            return {
                startPage:this.currentPage,
                lastPage:null,
                loopPager:null,
                selfTotalPage:this.totalPage
            }
        },
        methods: {
            setPager() {
                this.startPage = this.currentPage
                if(this.startPage <= 3 && this.selfTotalPage > 5) {
                    this.startPage = 1
                    this.lastPage = 5
                } else if (this.startPage <= 3 && this.selfTotalPage <= 5) {
                    this.startPage = 1
                    this.lastPage = this.selfTotalPage
                }
                if(this.startPage > 3) {
                    this.startPage = this.startPage - 2
                    this.lastPage = this.startPage + 4
                    if(this.lastPage > this.selfTotalPage) {
                        this.lastPage = this.selfTotalPage
                        this.startPage = this.lastPage - 4
                        if(this.startPage <= 0) {
                            this.startPage = 1
                        }
                    }
                }

                this.loopPager = this.lastPage - this.startPage +  1
            }
        },
        mounted() {
            this.setPager()
        }
    }
</script>

<style scoped>

</style>
