<template>
    <div>
        <div class="wine-list-wrap" v-if="wineList.length > 0">
            <!--<div class="wine-list-top">
                <div><span>'피노누아'</span>에 대한 <span>42</span>개의 검색 결과</div>
                <div>
                    <ul>
                        <li>최신순</li>
                        <li>가격 높은 순</li>
                        <li>가격 낮은 순</li>
                        <li>찜 많은 순</li>
                    </ul>
                </div>
            </div>-->
            <div class="wine-list-content">
                <WineCardItem
                v-for="wineInfo in wineList"
                :wine-info="wineInfo"
                v-bind:key="wineInfo.key"
                :is-redeem="isRedeem"
                >
                </WineCardItem>
            </div>
            <div class="wine-list-pager">
                <WinexPagination
                :current-page="pageInfo.current_page"
                :total-page="pageInfo.total_page"
                :move-page="movePage"></WinexPagination>
            </div>
        </div>
        <div class="pt-[160px] flex flex-col items-center w-full justify-center gap-[20px]"
             v-if="wineList.length === 0">
          <img src="../../assets/images/empty.png" class="w-[90px] h-[90px]" />
          <p class="text-[24px] text-lightDark font-[Suit-Medium]">NFT가 없습니다</p>
        </div>
    </div>
</template>

<script>
    import WineCardItem from "../common/WineCardItem";
    import WinexPagination from "../paginator/WinexPagination";
    export default {
        name: "PcWineList",
        components: {WinexPagination, WineCardItem},
        props: {
            wineList: {

            },
            isRedeem: {
                default:false
            },
            pageInfo: {required:true},
            movePage: {
                default: () => {},
                type:Function,
                required:true
            },
            noResultText: {
                type:String
            }
        }
    }
</script>

<style scoped>

</style>
