<template>
  <div class="wine-card-item-wrap">
    <div class="wine-card-info-wrap" @click="moveDetail">
      <!--<div class="wine-card-info-wrap">-->
      <div class="wine-image-wrap">
        <div class="wine-image" :style="wineImageStyle">

        </div>
      </div>
      <div class="wine-content-wrap">
        <div class="wine-text-info-wrap">
          <!-- Todo: 라벨 ui는 우선 주석 처리 해둠, 차후 마이페이지 쪽 거래내역 등에서는 활용 가능성이 존재 -->
          <!--                    <div class="wine-status-tag-wrap" v-if="wineInfo.status">-->
          <!--                        <div class="winex-tag primary-tag" v-if="wineInfo.status === 1">판매 중</div>-->
          <!--                        <div class="winex-tag dark-tag" v-if="wineInfo.status === 2">판매 완료</div>-->
          <!--                        <div class="winex-tag quaternary-tag" v-if="wineInfo.status === 3">실물 배송</div>-->
          <!--                    </div>-->
          <!-- Todo: 해당쪽도 주석 처리 요청 -->
          <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
          <p class="wine-title">{{ wineInfo.name }}</p>
        </div>
        <div class="wine-price-info">
          <!-- <span class="title">Last</span> -->
          <span class="price">{{ $numberFormat(wineInfo.price.coin) }} <span
              class="exchange-price">({{ $numberFormat(wineInfo.price.won).split('.')[0] }}원)</span></span>
        </div>

      </div>
    </div>
    <!-- TODO :: 20220720 임시 주석 처리 -->
    <!--<div class="card-bookmark" @click="sampleAlert">

    </div>-->
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "WineCardItem",
  props: {
    wineInfo: {
      required: true
    },
    isRedeem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('user', ['walletAddress'])
  },
  data() {
    return {
      wineImageStyle: {}
    }
  },
  methods: {
    moveDetail() {
      if(this.wineInfo.state === '03' && this.wineInfo.owner === this.walletAddress){
        this.$router.push('/redeem/complete/' + this.wineInfo.redeem_no)
      }else{
        this.$router.push('/market/detail/' + this.wineInfo.key)
      }
    },
    /*moveDetail(key) {
      this.$router.push('/market/detail/' + key)
    },
    moveRedeemDetail(redeemNo) {
      this.$router.push('/redeem/complete/' + redeemNo)
    }*/
  },
  created() {
    if (this.wineInfo.image) {
      this.wineImageStyle = {
        backgroundImage: `url(${this.wineInfo.image})`
      }
    }
  }
}
</script>

<style scoped>

</style>
