<template>
    <div>
        <PcWineList :wine-list="viewWineList" :page-info="pageInfo" :move-page="getWineList"></PcWineList>
    </div>
</template>

<script>
    import PcWineList from "../components/list/PcWineList";
    export default {
        name: "PcWineListSample",
        components: {PcWineList},
        data() {
            return {
                viewWineList:[],
                wineList: [
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                        key:'1',
                        price: {
                            coin:1270,
                            won:'5,260,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                    },
                    {
                        status:2,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019 ',
                        key:'0x09x08e09x456x154fsd',
                        price: {
                            coin:1023,
                            won:'3,450,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png"
                    },
                    {
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2018 ',
                        key:'3',
                        price: {
                            coin:621,
                            won:'3,180,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/694e43dfe6197.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2019 ',
                        key:'4',
                        price: {
                            coin:1270,
                            won:'2,730,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2018 ',
                        key:'5',
                        price: {
                            coin:386,
                            won:'2,400,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/49d971f480586.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Laurent Roumier 4종 ',
                        key:'6',
                        price: {
                            coin:273,
                            won:'980,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/ed8551e9e72fb.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Bollinger La Grande Année 2012 ',
                        key:'7',
                        price: {
                            coin:105,
                            won:'470,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/d0c1e564d8f76.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC La Tache Grand Cru 2017 ',
                        key:'8',
                        price: {
                            coin:3672,
                            won:'27,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png"
                    },
                    {
                        status:3,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC Echezeaux Grand Cru 2018 ',
                        key:'9',
                        price: {
                            coin:1270,
                            won:'15,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Louis Roederer Cristal 2009 1.5 Magnum ',
                        key:'10',
                        price: {
                            coin:230,
                            won:'1,280,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220602/b65093f040236.jpg"
                    },
                ],
                secondWineList: [
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Louis Roederer Cristal 2009 1.5 Magnum ',
                        key:'10',
                        price: {
                            coin:230,
                            won:'1,280,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220602/b65093f040236.jpg"
                    },
                    {
                        status:2,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019 ',
                        key:'0x09x08e09x456x154fsd',
                        price: {
                            coin:1023,
                            won:'3,450,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2019 ',
                        key:'4',
                        price: {
                            coin:1270,
                            won:'2,730,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2018 ',
                        key:'5',
                        price: {
                            coin:386,
                            won:'2,400,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/49d971f480586.png"
                    },
                    {
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2018 ',
                        key:'3',
                        price: {
                            coin:621,
                            won:'3,180,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/694e43dfe6197.png"
                    },
                    {
                        status:3,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC Echezeaux Grand Cru 2018 ',
                        key:'9',
                        price: {
                            coin:1270,
                            won:'15,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png"
                    },

                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                        key:'1',
                        price: {
                            coin:1270,
                            won:'5,260,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                    },
                ],
                pageInfo: {
                    current_page:1,
                    total_page:2
                }
            }
        },
        created() {
            this.viewWineList = this.wineList
        },
        methods: {
            getWineList(page) {
                // 통신 처리
                this.pageInfo.current_page = page
                if(page === 1) {
                    this.viewWineList = this.wineList
                } else if(page === 2) {
                    this.viewWineList = this.secondWineList
                }
            }
        }
    }
</script>

<style scoped>

</style>
